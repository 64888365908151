import BottleGoose from './svg/BottleGoose';


export const SVG = {
  BottleGoose,
}

export const ASSETS = {
  MONEY_BG: require('./img/money-bg.jpg'),
  ZALO: require('./img/zalo.png'),
  BLUE_BG: require('./img/blue-bg.png'),
  BLACK_BG: require('./img/black-bg.png'),
  RED_BG: require('./img/red-bg.png'),
}