import React, { useState, useEffect } from 'react';
import { Col, Row, Text, RatioCol, MoneyImage } from 'components';
import { Image } from 'react-native';
import Store from 'store';
import { useNavFunc } from 'navigation';
import { SCREEN } from 'const';
import { VarHelper } from 'helpers';
import Slider from 'react-slick';

const min6 = arr => {
    let res = arr.slice();
    if (res.length >= 6) return res;
    const times = Math.ceil(6 / arr.length);
    for (let i=0; i<times; i++) {
        res = [
            ...res,
            ...arr
        ]
    }
    return res;
}

export const Slideshow = () => {
    const { navigation } = useNavFunc();
    const [products, setProducts] = useState([]);
    useEffect(() => {
        Store.Api.Collection.slideshow().then(res => {
            if (res.data.success) {
                setProducts(res.data.data);
            }
        })
    }, []);

    const handlePress = (p) => {
        navigation.reset({
            index: 0,
            routes: [{
                name: SCREEN.CollectionDetail,
                params: {
                    id: p.collection.id,
                    slug: VarHelper.toSlug(p.collection.name),
                    focus: p.id,
                },
            }],
        });
    };

    return products.length === 0 ? null : (
        <Col p2 paddingBottom={0}>
            {/* <Row absolute top={0} left={0} bottom={0} p2>
            
                
            </Row> */}
            <div>
                <Slider
                    slidesToShow={6}
                    slidesToScroll={1}
                    arrows={false}
                    dots={false}
                    autoplay
                    responsive={[
                        {
                          breakpoint: 1050,
                          settings: {
                            slidesToShow: 6,
                            slidesToScroll: 1,
                          }
                        },
                        {
                          breakpoint: 480,
                          settings: {
                            slidesToShow: 2,
                            slidesToScroll: 1,
                          }
                        }
                      ]}
                >
                    {min6(products).map((val, pI) => {
                        return (
                            <div className="abc" key={'item-'+pI}>
                                <Col key={val.id}>
                                    <RatioCol width={'100%'} ratio={316 / 160} onPress={() => handlePress(val)}>
                                        <Col flex1 middle p1>
                                            {val.commonImages && val.commonImages[0] ? (
                                                <Image source={{ uri: val.commonImages[0] }} style={{ width: '100%', height: '100%' }} resizeMode='cover' />
                                            ) : (
                                                <MoneyImage
                                                    {...val}
                                                />
                                            )}

                                        </Col>
                                    </RatioCol>
                                </Col>
                            </div>
                        )
                    })}
                </Slider>
            </div>
        </Col>
    );
};

export default Slideshow;
