import React, { useEffect } from 'react';
import { Grid, Col, HomeHeader, } from 'components';
import CartSidebar from 'screens/home2/Home.CartSidebar';
import CartBottomBar from 'screens/home2/Home.Cart';
import { FacebookProvider, Page, CustomChat } from 'react-facebook';
import { useNavFunc } from 'navigation';
import { IS_KHOTIENNAMSINH_COM, SITE_INFO } from 'const';
import Slideshow from 'screens/home2/Slideshow';
import { Nav } from './Nav';
import { useDynamicResponsiveValue } from 'quickly-react';
import { ASSETS } from 'assets';
import { Stats } from './Stats';
import { FacebookPage } from './FacebookPage';
import ReactGA from "react-ga4";

export const HomeLayout = ({ children, onPlaceOrderDone }: any) => {
    const { navigate, route } = useNavFunc();
    // @ts-ignore
    const { ctv }: any = route.params || {};
    console.log('route', route);

    useEffect(() => {
        if (SITE_INFO.GOOGLE_ANALYTIC) {
            ReactGA.initialize(SITE_INFO.GOOGLE_ANALYTIC);
        }
    }, []);

    const rV = useDynamicResponsiveValue(['xs', 'md']);
    const breakpoint = rV({ xs: 'xs', md: 'md' });
    console.log('breakpoint', breakpoint);

    const bg = (() => {
        if (!!ctv) return '';
        if (
            ['localhost','shoptiennamsinh.net'].includes(window.location.hostname)
        ) {
            return ASSETS.BLUE_BG
        }
        if (
            ['shoptiensinhnhat.net'].includes(window.location.hostname)
        ) {
            return ASSETS.BLACK_BG;
        }
        if (IS_KHOTIENNAMSINH_COM) return ASSETS.RED_BG;
        return ASSETS.BLUE_BG;
    })();

    const renderMainContent = () => {
        console.log('SITE_INFO.SLIDESHOW', SITE_INFO.SLIDESHOW);
        return (
            <Col flex1 backgroundColor={'#E9E9F0'}
                style={bg ? {
                    // @ts-ignore
                    backgroundImage: `url("${bg}")`,
                    backgroundSize: '100% 100%',
                } : {}}
            >
                {SITE_INFO.SLIDESHOW && (
                    <Slideshow />
                )}
                <Grid flex1 xs='100%' md='any:1' p2 alignItems={'flex-start'}>
                    {breakpoint !== 'xs' && route.name !== 'Home' && route.name !== 'Embed' ? (
                        <Col width={275} mr2>
                            <CartSidebar
                                ctv={ctv}
                                onPlaceOrderDone={onPlaceOrderDone}
                            />
                            {SITE_INFO.CATEGORY_POSITION === 'left' && !ctv && (
                                <Nav />
                            )}
                            {!ctv && <FacebookPage mt2 />}
                        </Col>
                    ) : <Col />}
                    <Col>
                        {(breakpoint === 'xs' || SITE_INFO.CATEGORY_POSITION === 'top' || !!ctv) && <HomeHeader />}
                        {children}
                    </Col>
                </Grid>
                {(breakpoint === 'xs' || !!ctv) && (
                    <CartBottomBar
                        ctv={ctv}
                        onPlaceOrderDone={onPlaceOrderDone}
                    />
                )}
            </Col>
        )
    }
    return (!ctv && !!SITE_INFO.FB_APP_ID) ? (
        <FacebookProvider appId={SITE_INFO.FB_APP_ID} chatSupport>
            {renderMainContent()}
            {SITE_INFO.FB_PAGE_ID && (
                <CustomChat pageId={SITE_INFO.FB_PAGE_ID} themeColor={SITE_INFO.BRAND_COLOR} />
            )}
        </FacebookProvider>
    ) : renderMainContent();
};

export default HomeLayout;