import React, { Component } from 'react';
import { withResizeDetector } from 'react-resize-detector';
import ImageRatio from './ImageRatio';

import img500d from 'assets/img/money/500d.jpg';
import img1000d from 'assets/img/money/1000d.jpg';
import img2000d from 'assets/img/money/2000d.jpg';
import img5000d from 'assets/img/money/5000d.jpg';
import img10000d_p from 'assets/img/money/10000d_p.jpg';
import img20000d_p from 'assets/img/money/20000d_p.jpg';
import img50000d_p from 'assets/img/money/50000d_p.jpg';
import img10000d_g from 'assets/img/money/10000d_g.jpg';

import img500000d from 'assets/img/money/500k.png';
import img200000d from 'assets/img/money/200k.jpg';
import img100000d from 'assets/img/money/100k.jpg';

import img100d from 'assets/img/money/100d.jpg';
import img200d from 'assets/img/money/200d.jpg';

import img1usd from 'assets/img/money/1usd.jpg';
import img2usd from 'assets/img/money/2usd.jpg';

import './MoneyImage.css';

import Vnd5k from './Vnd5k';
import Vnd1k from './Vnd1k';
import Vnd2k from './Vnd2k';
import Vnd500 from './Vnd500';
import ContainerRatio from './ContainerRatio';

class MoneyImage extends Component {

  state = {

  }

  render() {
    const { currency, seri, code, displayValue, width, height } = this.props;
    // 1 nghìn đồng
    if (currency === 'VND' && displayValue == '1000')
      return <Vnd1k code={code} seri={seri} />
      // return <image-money type="1k" code={code} seri={seri}></image-money>
      // return (
      //   <div
      //     style={{ width: '100%' }}
      //     dangerouslySetInnerHTML={{
      //       __html: `<money-image type="1k" code="${code}" seri="${seri}"></image-money>`
      //     }}
      //   />
      // )
    // 500 đồng        
    if (currency === 'VND' && displayValue == '500')
      return <Vnd500 seri={seri} code={code} />
    // 2000 đồng  
    if (currency === 'VND' && displayValue == '2000')
      return <Vnd2k seri={seri} code={code} />
    // 5000 đồng
    if (currency === 'VND' && displayValue == '5000')
    return (
      <Vnd5k code={code} seri={seri} />
    )
      
    // 10000 polyme
    if (currency === 'VND' && displayValue == '10000' && seri.length === 8)
      return (
        <div className="image-money-container vnd10000">
          <ImageRatio
            src={img10000d_p}
            ratio={2205 / 1000}
          />
          <div className="code-seri-vertical">
            {`${code}${seri}`.split('').map((character, i) => (
              <p key={'character-vertical-' + i} style={{ fontSize: height * 0.055, margin: 0, marginBottom: height * 0.056, lineHeight: 0 }} >{character}</p>
            ))}
          </div>
          <div className="code-seri-horizontal">
            <span style={{ fontSize: width * 0.033, margin: 0, marginRight: width * 0.015 }} >{code}</span>
            {`${seri}`.split('').map((character, i) => (
              <span key={'character-horizontal-' + i} style={{ fontSize: width * 0.03 + i * width * 0.0015, margin: 0, marginLeft: width * 0.004 }} >{character}</span>
            ))}
          </div>
        </div>
      );
    // 500k
    if (currency === 'VND' && displayValue == '500000')
    return (
      <div className="image-money-container vnd500000">
        <ImageRatio
          src={img500000d}
          ratio={1402 / 600}
        />
        <div className="code-seri-vertical">
          {`${code}${seri}`.split('').map((character, i) => (
            <p key={'character-vertical-' + i} style={{ fontSize: height * 0.055, margin: 0, marginBottom: height * 0.0545, lineHeight: 0 }} >{character}</p>
          ))}
        </div>
        <div className="code-seri-horizontal">
          <span style={{ fontSize: width * 0.033, margin: 0, marginRight: width * 0.015 }} >{code}</span>
          {`${seri}`.split('').map((character, i) => (
            <span key={'character-horizontal-' + i} style={{ fontSize: width * 0.03 + i * width * 0.0015, margin: 0, marginLeft: width * 0.004 }} >{character}</span>
          ))}
        </div>
      </div>
    );
    // 10000 giấy
    if (currency === 'VND' && displayValue == '10000' && seri.length === 7)
      return (
        <div className="image-money-container vnd10000">
          <ImageRatio
            src={img10000d_g}
            ratio={2560 / 1241}
          />
          <div
            className="code-seri"
            style={{
              top: height * 0.29,
              left: width * 0.148,
              textShadow: `0 0 ${width * 0.0015}px rgba(33,33,32, 1)`,
              fontWeight: '200'
            }}
          >
            <span className="code" style={{ fontSize: width * 0.03, opacity: 0.97 }}>{code}</span>
            <span className="seri" style={{ fontSize: width * 0.035, marginLeft: width * 0.02 }}>{seri}</span>
          </div>
          <div
            className="code-seri"
            style={{
              bottom: height * 0.12,
              right: width * 0.07,
              textShadow: `0 0 ${width * 0.0015}px rgba(33,33,32, 1)`,
              fontWeight: '200'
            }}
          >
            <span className="code" style={{ fontSize: width * 0.03, opacity: 0.97 }}>{code}</span>
            <span className="seri" style={{ fontSize: width * 0.035, marginLeft: width * 0.02 }}>{seri}</span>
          </div>
        </div>
      );
    if (currency === 'VND' && displayValue == '20000' && seri.length === 8)
      return (
        <div className="image-money-container vnd20000">
          <ImageRatio
            src={img20000d_p}
            ratio={2298 / 1134}
          />
          <div className="code-seri-vertical">
            {`${code}${seri}`.split('').map((character, i) => (
              <p key={'character-vertical-' + i} style={{ fontSize: height * 0.055, margin: 0, marginBottom: height * 0.055, lineHeight: 0 }} >{character}</p>
            ))}
          </div>
          <div className="code-seri-horizontal">
            <span style={{ fontSize: width * 0.033, margin: 0, marginRight: width * 0.015 }} >{code}</span>
            {`${seri}`.split('').map((character, i) => (
              <span key={'character-horizontal-' + i} style={{ fontSize: width * 0.03 + i * width * 0.0015, margin: 0, marginLeft: width * 0.004 }} >{character}</span>
            ))}
          </div>
        </div>
      );
    if (currency === 'VND' && displayValue == '50000' && seri.length === 8)
      return (
        <div className="image-money-container vnd50000">
          <ImageRatio
            src={img50000d_p}
            ratio={2275 / 1069}
          />
          <div className="code-seri-vertical">
            {`${code}${seri}`.split('').map((character, i) => (
              <p key={'character-vertical-' + i} style={{ fontSize: height * 0.055, margin: 0, marginBottom: height * 0.055, lineHeight: 0 }} >{character}</p>
            ))}
          </div>
          <div className="code-seri-horizontal">
            <span style={{ fontSize: width * 0.033, margin: 0, marginRight: width * 0.015 }} >{code}</span>
            {`${seri}`.split('').map((character, i) => (
              <span key={'character-horizontal-' + i} style={{ fontSize: width * 0.03 + i * width * 0.0015, margin: 0, marginLeft: width * 0.004 }} >{character}</span>
            ))}
          </div>
        </div>
      );

      // 100 đồng  
    if (currency === 'VND' && displayValue == '100')
    return (
      <div className="image-money-container vnd1000">
        <ImageRatio
          src={img100d}
          ratio={2560 / 1286}
        />
        <div
          className="code-seri"
          style={{
            top: height * 0.27,
            left: width * 0.39,
            textShadow: `0 0 ${width * 0.0015}px rgba(161, 21, 19, 1)`,
            fontWeight: '400',
            transform: 'rotate(0.5deg)'
          }}
        >
          <span className="code" style={{ fontSize: width * 0.03 }}>{code}</span>
          <span className="seri" style={{ fontSize: width * 0.035, marginLeft: width * 0.02 }}>{seri}</span>
        </div>
      </div>
    );

    // 200 đồng  
    if (currency === 'VND' && displayValue == '200') {
      return (
        <div className="image-money-container vnd1000">
          <ImageRatio
            src={img200d}
            ratio={700 / 341}
          />
          <div
            className="code-seri"
            style={{
              top: height * 0.18,
              left: width * 0.39,
              textShadow: `0 0 ${width * 0.0015}px rgba(161, 21, 19, 1)`,
              fontWeight: '500'
            }}
          >
            <span className="code" style={{ fontSize: width * 0.03 }}>{code}</span>
            <span className="seri" style={{ fontSize: width * 0.035, marginLeft: width * 0.02 }}>{seri}</span>
          </div>
        </div>
      );
    }
    

    // 1 usd
    if (currency === 'USD' && displayValue == '1') {
      const [firstLetter, secondLetter] = !!code ? code.split('') : ['B', 'A'];
      return (
        <div className="image-money-container usd1">
          <ImageRatio
            src={img1usd}
            ratio={2560 / 1081}
          />
          <div
            className="code-seri"
            style={{
              top: width <= 345 ? height * 0.26 : height * 0.272,
              left: width * 0.615,
              textShadow: `0 0 ${width * 0.0015}px rgba(17, 98, 60, 1)`,
              fontWeight: '500',
              letterSpacing: width * 0.002
            }}
          >
            <span className="seri" style={{ fontSize: width * 0.032, marginLeft: width * 0.02 }}>{firstLetter}{seri}{secondLetter}</span>
          </div>
          <div
            className="code-seri"
            style={{
              top: height * 0.61,
              left: width * 0.12,
              textShadow: `0 0 ${width * 0.0015}px rgba(17, 98, 60, 1)`,
              fontWeight: '500',
              letterSpacing: width * 0.002
            }}
          >
            <span className="seri" style={{ fontSize: width * 0.032, marginLeft: width * 0.02 }}>{firstLetter}{seri}{secondLetter}</span>
          </div>
        </div>
      );
    }

    // 2 usd
    if (currency === 'USD' && displayValue == '2') {
      const [firstLetter, secondLetter] = !!code ? code.split('') : ['B', 'A'];
      return (
        <div className="image-money-container usd1">
          <ImageRatio
            src={img2usd}
            ratio={2560 / 1083}
          />
          <div
            className="code-seri"
            style={{
              top: height * 0.22,
              left: width * 0.625,
              textShadow: `0 0 ${width * 0.0015}px rgba(17, 98, 60, 1)`,
              letterSpacing: width * 0.0045
            }}
          >
            <span className="seri" style={{ fontSize: width * 0.032, marginLeft: width * 0.02 }}>{firstLetter}{seri}{secondLetter}</span>
          </div>
          <div
            className="code-seri"
            style={{
              top: height * 0.565,
              left: width * 0.14,
              textShadow: `0 0 ${width * 0.0015}px rgba(17, 98, 60, 1)`,
              letterSpacing: width * 0.0045
            }}
          >
            <span className="seri" style={{ fontSize: width * 0.032, marginLeft: width * 0.02 }}>{firstLetter}{seri}{secondLetter}</span>
          </div>
        </div>
      );
    }
    
    // 200k
    if (currency === 'VND' && displayValue == '200000')
    return (
      <div className="image-money-container vnd10000">
        <ImageRatio
          src={img200000d}
          ratio={2528 / 1087}
        />
        <div className="code-seri-vertical" style={{ top: '22.5%', left: '3%' }}>
          {`${code}${seri}`.split('').map((character, i) => (
            <p key={'character-vertical-' + i} style={{ fontSize: height * 0.055, margin: 0, marginBottom: height * 0.055, lineHeight: 0 }} >{character}</p>
          ))}
        </div>
        <div className="code-seri-horizontal">
          <span style={{ fontSize: width * 0.024, margin: 0, marginRight: width * 0.016 }} >{code}</span>
          {`${seri}`.split('').map((character, i) => (
            <span key={'character-horizontal-' + i} style={{ fontSize: width * 0.024 + i * width * 0.0017, margin: 0, marginLeft: width * 0.004 }} >{character}</span>
          ))}
        </div>
      </div>
    );

    // 100k
    if (currency === 'VND' && displayValue == '100000')
    return (
      <div className="image-money-container vnd10000">
        <ImageRatio
          src={img100000d}
          ratio={2519 / 1127}
        />
        <div className="code-seri-vertical" style={{ top: '24.5%', left: '2.5%' }}>
          {`${code}${seri}`.split('').map((character, i) => (
            <p key={'character-vertical-' + i} style={{ fontSize: height * 0.055, margin: 0, marginBottom: height * 0.055, lineHeight: 0 }} >{character}</p>
          ))}
        </div>
        <div className="code-seri-horizontal">
          <span style={{ fontSize: width * 0.033, margin: 0, marginRight: width * 0.015 }} >{code}</span>
          {`${seri}`.split('').map((character, i) => (
            <span key={'character-horizontal-' + i} style={{ fontSize: width * 0.03 + i * width * 0.0015, margin: 0, marginLeft: width * 0.004 }} >{character}</span>
          ))}
        </div>
      </div>
    );

    return (
      <div className="image-money-container vnd2000" style={{ backgroundColor: '#cecece' }}>
        <ContainerRatio
          ratio={2560 / 1272}
        >
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
            <p>Đang cập nhật {`(${displayValue} ${currency})`}</p>
          </div>
        </ContainerRatio>
      </div>
    )
  }
}

export default withResizeDetector(MoneyImage);
