import React, { useState, useEffect } from 'react';
import Store from 'store';
import { Col, Row, Text } from 'components';
import { VarHelper } from 'helpers';
import { COLOR, IS_MOBILE_NAV_DARK_BACKGROUND, IS_SHOPTIENNAMSINH_NET } from 'const';
import { useDynamicResponsiveValue } from 'quickly-react';
import { useNavFunc } from 'navigation';
import Select from 'react-select';

let tempList = [{ name: 'Tiền năm sinh', url: '/' }];

const HomeHeader = () => {

    const { route } = useNavFunc();
    // console.log('route', route);
    const [list, setList] = useState(tempList.slice());
    const rV = useDynamicResponsiveValue(['xs', 'md']);
    const breakpoint = rV({ xs: 'xs', md: 'md' });
    useEffect(() => {
        Store.Api.Collection.list({}).then((res) => {
            if (res.data.success && res.data.data.list) {
                setList([
                    { name: 'Tiền năm sinh', url: '/' },
                    ...res.data.data.list.map(v => ({ name: v.name, url: `/collection/${v.id}/${VarHelper.toSlug(v.name)}` }))
                ])
            }
        });
    }, []);

    if (list.length <= 1) return null;
    const activeColor = IS_MOBILE_NAV_DARK_BACKGROUND ? 'yellow' : 'white';
    const normalColor = IS_MOBILE_NAV_DARK_BACKGROUND ? 'white' : COLOR.MAIN;
    if (IS_SHOPTIENNAMSINH_NET) {
        const options = list.map(v => ({
            label: v.name,
            value: v.url,
            data: v,
            isActice:  window.location.pathname === v.url
        }));
        const findActive = options.find(v => v.isActice);
        return (
            <Col mb2>
                <Select
                    options={options}
                    value={findActive}
                    onChange={(newData) => {
                        window.location.pathname = newData.value;
                    }}
                    menuPortalTarget={document.body}
                />
            </Col>
        )
    }
    return (
        <Col middle>
            <Row flexWrap={'wrap'} width={rV({ xs: '100%', md: '50%' })} maxWidth={500} mb2 marginHorizontal={-10} middle={breakpoint !== 'xs'}>
                {list.map((v, i) => {
                    const isActive = window.location.pathname === v.url;
                    return (
                        <Col m0 key={'nav-'+i} p1 round1 borderWidth={1} borderColor={COLOR.MAIN} backgroundColor={isActive ? COLOR.MAIN : 'transparent'}
                            onPress={() => {
                                window.location.pathname = v.url;
                            }}
                        >
                            <Text color={isActive ? activeColor : normalColor}>{v.name}</Text>
                        </Col>
                    )
                })}
            </Row>
        </Col>
    );
};


export default HomeHeader;