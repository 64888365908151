import React, { useState, useEffect, useRef, useMemo } from 'react';
import { Col, Text, Header, Input, Row, Button, useUIState, Grid, MoneyImage, RatioCol, ApolloEffect, modal, TouchField, HomeLayout, FacebookPage } from 'components';
import { IScreen } from 'type';
import Store from 'store';
import { useNavFunc } from 'navigation';
import { SCREEN, SITE_INFO, COLOR, IS_TIENNAMSINH_NET, IS_DARK_BACKGROUND, IS_KHOTIENNAMSINH_COM } from 'const';
import { ActivityIndicator, Image, ScrollView } from 'react-native';
import { useDynamicResponsiveValue } from 'quickly-react';
import { AntDesign, FontAwesome5, EvilIcons, MaterialIcons, Entypo, FontAwesome } from '@expo/vector-icons';
import { ASSETS } from 'assets';
import { FacebookProvider, Page, CustomChat } from 'react-facebook';
import SearchForm from './Home.SearchForm';
import { SubmitOrder } from './Home.SubmitOrder';
import Cart from './Home.Cart';
import CartSidebar from './Home.CartSidebar';
import { TimeHelper, VarHelper } from 'helpers';
import Swal from 'sweetalert2'

const showError = (message) => {
  Swal.fire({
    title: 'Có lỗi xảy ra!',
    text: message,
    icon: 'error',
    confirmButtonText: 'OK'
  })
}

const showAlert = message => {
  Swal.fire(
    'Thành công!',
    message,
    'success'
  )
}

const isInIframe = () => {
  try {
    return window.self !== window.top;
  } catch (e) {
    return true;
  }
}

const NewMoney = (val) => {
  console.log(val);
  let shouldUseNewImage = false;
  let matchCode = '';

  const matchData = [
    {
      displayValue: '100',
      currency: 'VND',
      match: '100d',
      short: '0.1'
    },
    {
      displayValue: '200',
      currency: 'VND',
      match: '200d',
      short: '0.2'
    },
    {
      displayValue: '500',
      currency: 'VND',
      match: '500d',
      short: '0.5'
    },
    {
      displayValue: '1000',
      currency: 'VND',
      match: '1k',
      short: '1'
    },
    {
      displayValue: '2000',
      currency: 'VND',
      match: '2k',
      short: '2'
    },
    {
      displayValue: '5000',
      currency: 'VND',
      match: '5k',
      short: '5'
    },
    {
      displayValue: '10000',
      currency: 'VND',
      match: '10k',
      short: '10'
    },
    {
      displayValue: '10000-giay',
      currency: 'VND',
      match: '10k-giay',
      short: '10 đỏ'
    },
    {
      displayValue: '20000',
      currency: 'VND',
      match: '20k',
      short: '20'
    },
    {
      displayValue: '20000',
      currency: 'VND',
      match: '20k-giay',
      short: '20 xanh'
    },
    {
      displayValue: '50000',
      currency: 'VND',
      match: '50k',
      short: '50'
    },
    {
      displayValue: '100000',
      currency: 'VND',
      match: '100k',
      short: '100'
    },
    {
      displayValue: '200000',
      currency: 'VND',
      match: '200k',
      short: '200'
    },
    {
      displayValue: '500000',
      currency: 'VND',
      match: '500k',
      short: '500'
    },
    {
      displayValue: '1',
      currency: 'USD',
      match: '1usd',
      short: '1usd'
    },
    {
      displayValue: '2',
      currency: 'USD',
      match: '2usd',
      short: '2usd'
    },
    {
      displayValue: '5',
      currency: 'USD',
      match: '5usd',
      short: '5usd'
    },
    {
      displayValue: '100',
      currency: 'USD',
      match: '100usd',
      short: '100usd'
    }
  ]

  if (IS_KHOTIENNAMSINH_COM && !isInIframe() ) {
    // CACH 1
    switch(true) {
      case (val.currency === 'VND' && val.displayValue == '100' && val.short == '0.1'):
        shouldUseNewImage = true; matchCode = '100d'; 
      break;
      case (val.currency === 'VND' && val.displayValue == '200' && val.short == '0.2'):
        shouldUseNewImage = true; matchCode = '200d';
      break;
      case (val.currency === 'VND' && val.displayValue == '500' && val.short == '0.5'):
        shouldUseNewImage = true; matchCode = '500d';
      break;
      case (val.currency === 'VND' && val.displayValue == '1000' && val.short == '1'):
        shouldUseNewImage = true; matchCode = '1k';
      break;
      case (val.currency === 'VND' && val.displayValue == '2000' && val.short == '2'):
        shouldUseNewImage = true; matchCode = '2k';
      break;
      case (val.currency === 'VND' && val.displayValue == '5000' && val.short == '5'):
        shouldUseNewImage = true; matchCode = '5k';
      break;
      
      case (val.currency === 'VND' && val.displayValue == '10000' && val.short == '10 đỏ'):
        shouldUseNewImage = true; matchCode = '10k-giay';
      break;
      case (val.currency === 'VND' && val.displayValue == '10000' && val.short == '10'):
        shouldUseNewImage = true; matchCode = '10k';
      break;
      case (val.currency === 'VND' && val.displayValue == '20000' && val.short == '20'):
        shouldUseNewImage = true; matchCode = '20k';
      break;
      case (val.currency === 'VND' && val.displayValue == '20000' && val.short == '20-xanh'):
        shouldUseNewImage = true; matchCode = '20k-giay';
      break;
      case (val.currency === 'VND' && val.displayValue == '50000' && val.short == '50'):
        shouldUseNewImage = true; matchCode = '50k';
      break;
      case (val.currency === 'VND' && val.displayValue == '100000' && val.short == '100'):
        shouldUseNewImage = true; matchCode = '100k';
      break;
      case (val.currency === 'VND' && val.displayValue == '200000' && val.short == '200'):
        shouldUseNewImage = true; matchCode = '200k';
      break;
      case (val.currency === 'VND' && val.displayValue == '500000' && val.short == '500'):
        shouldUseNewImage = true; matchCode = '500k';
      break;
      case (val.currency === 'USD' && val.displayValue == '1' && val.short == '1usd'):
        shouldUseNewImage = true; matchCode = '1usd';
      break;
      case (val.currency === 'USD' && val.displayValue == '2' && val.short == '2usd'):
        shouldUseNewImage = true; matchCode = '2usd';
      break;
      case (val.currency === 'USD' && val.displayValue == '5' && val.short == '5usd'):
        shouldUseNewImage = true; matchCode = '5usd';
      break;
      case (val.currency === 'USD' && val.displayValue == '100' && val.short == '100usd'):
        shouldUseNewImage = true; matchCode = '100usd';
      break;
    }

    // CACH 2
    // const findMatch = matchData.find(v => v.currency === val.currency && v.displayValue === val.displayValue && v.short === val.short);
    // if (findMatch) {
    //   shouldUseNewImage = true;
    //   matchCode = findMatch.match;
      
    // }
  }
  
  // if (val.currency === 'VND' && val.displayValue == '2000' && IS_KHOTIENNAMSINH_COM)
  //   return (
  //     <RatioCol width={'100%'} ratio={316 / 160}>
  //       <div
  //         style={{ width: '93%', margin: 'auto' }}
  //         // style={{ position: 'absolute', top: 0, right: 0, bottom: 0, left: 0 }}
  //         dangerouslySetInnerHTML={{
  //           __html: `<money-image type="2k" code="${val.code}" seri="${val.seri}"></money-image>`
  //         }}
  //       />
  //     </RatioCol>
      
  //   );

  if (shouldUseNewImage) {
    return (
      <RatioCol width={'100%'} ratio={316 / 160}>
        <div
          style={{ width: '93%', margin: 'auto' }}
          // style={{ position: 'absolute', top: 0, right: 0, bottom: 0, left: 0 }}
          dangerouslySetInnerHTML={{
            __html: `<money-image type="${matchCode}" code="${val.code}" seri="${val.seri}"></money-image>`
          }}
        />
      </RatioCol>
    )
  }
  return (
    <RatioCol width={'100%'} ratio={316 / 160}>
      <Col flex1 middle p1 backgroundColor={'rgba(0,0,0,0.3)'}>
          <MoneyImage
            {...val}
          />
        </Col>
    </RatioCol>
  );
}

const Home: IScreen = () => {
  const { navigate, route } = useNavFunc();
  const { ctv }: any = route.params || {};
  const [ngaythangnamsinh, setNgaythangnamsinh] = useState(['', undefined, '']);
  const [yearPairs, setYearPairs] = useState(['', '']);
  const [listSearch, setListSearch] = useState([]);
  const [carts, setCarts] = Store.useCartStore();
  const [message, setMessage] = useState('');

  const listQuyUoc = useRef([]);
  const rV = useDynamicResponsiveValue(['xs', 'md', 'lg']);
  const breakpoint = rV({ xs: 'xs', md: 'md', lg: 'lg' });

  const [{ loading: form1Loading }, setForm1UI] = useUIState();
  const [{ loading: form2Loading }, setForm2UI] = useUIState();

  useEffect(() => {
    if (ctv) {
      console.log('useEffect ctv', ctv);
    }
  }, []);

  useEffect(() => {
    (async () => {
      const res = await Store.Api.QuyUoc.list({});
      if (res.data.data) {
        listQuyUoc.current = res.data.data;
      }
    })();
  }, []);

  const getPrice = ({ short, seri }) => {
    // console.log('getPrice seri', seri);
    const isCapDoi = (() => {
      if (!seri || seri.length !== 8) return false;
      const p1 = +seri.slice(0, 4);
      const p2 = +seri.slice(4, 8);
      if (isNaN(p1) || isNaN(p2)) return false;
      if (p1 < 1950 || p1 > 2100) return false;
      if (p2 < 1950 || p2 > 2100) return false;
      return true;
    })();
    const find = listQuyUoc.current.find(val => val.short === short);
    // console.log('find', find, isCapDoi);
    if (!isCapDoi) return !find ? 0 : find.price;
    const isSpecial = seri.substring(0, 4) === seri.substring(4, 8);
    return isSpecial ? find.priceYearPairSpecial : (find.priceYearPair || find.price);
  };

  const traCuu = async () => {
    if (ngaythangnamsinh.filter(val => !!val).length !== 3) return showError('Vui lòng điền ngày tháng năm sinh');
    if (!IS_KHOTIENNAMSINH_COM) {
      if (+ngaythangnamsinh[0] > 31) return showError('Ngày không hợp lệ, vui lòng điền trong khoảng 1-31');
      if (+ngaythangnamsinh[1] > 12) return showError('Tháng không hợp lệ, vui lòng điền trong khoảng 1-12');
      if (+ngaythangnamsinh[2] > 3000) return showError('Năm không hợp lệ');
    }
    setForm1UI({ loading: true });
    await TimeHelper.wait(300);
    const query = ngaythangnamsinh.join('/');
    const res = await Store.Api.Money.list({
      query,
      saleStatus: 'available',
    })
    console.log('res', res.data);
    // TRang tiennamsinh.net sẽ lấy dữ liệu từ khotiennamsinh.com
    let list2 = [];
    if (IS_TIENNAMSINH_NET) {
      try {
        const resKhotien = await fetch(`https://api.khotiennamsinh.com/api/money?query=${query}&saleStatus=available`);
        const jsonKhotien = await resKhotien.json();
        const listKhoTien = jsonKhotien.data?.list || [];
        list2 = listKhoTien.map(v => ({ ...v, source: 'khotiennamsinh.com' }));
      } catch (err) { }
    }
    if (res.data.error) {
      showError(res.data.error);
    } else {
      const { list } = res.data.data;
      const mergedList = [...list, ...list2];
      setListSearch(withDisplayOrder(mergedList));
      if (mergedList.length === 0) {
        setMessage('Số này hiện không có, bạn liên hệ Fanpage hoặc Zalo để được tư vấn thêm');
      }
    }
    setForm1UI({ loading: false });
    document.querySelector('#list-search-results')?.scrollIntoView({
      behavior: 'smooth'
    });

  }

  const onTraCuuCapDoi = async () => {
    const [year1, year2] = yearPairs;
    if (!year1 || !year2) return showError('Vui lòng điền đủ 2 năm sinh');
    if (year1.length !== 4 || year2.length !== 4) return showError('Vui lòng nhập năm sinh cặp đôi theo dạng số năm từ 1950-2050');
    if (+year1 < 1950 || +year1 > 2050) return showError('Vui lòng nhập năm sinh cặp đôi theo dạng số năm từ 1950-2050');
    if (+year2 < 1950 || +year2 > 2050) return showError('Vui lòng nhập năm sinh cặp đôi theo dạng số năm từ 1950-2050');
    setForm2UI({ loading: true });
    await TimeHelper.wait(300);
    const res = await Store.Api.Money.listCapDoi({
      year1,
      year2,
    })
    console.log('res', res.data);
    if (res.data.error) {
      showError(res.data.error);
    } else {

      let list2 = [];
      if (IS_TIENNAMSINH_NET) {
        try {
          const resKhotien = await fetch(`https://api.khotiennamsinh.com/api/money/cap-doi?year1=${year1}&year2=${year2}`);
          const jsonKhotien = await resKhotien.json();
          const listKhoTien = jsonKhotien.data?.list || [];
          list2 = listKhoTien.map(v => ({ ...v, source: 'khotiennamsinh.com' }));
        } catch (err) { }
      }

      const { list } = res.data.data;
      const exactSeri = `${year1}${year2}`;
      const listExact = list.filter(val => val.seri === exactSeri);
      const list2Exact = list2.filter(val => val.seri === exactSeri);
      const listNotExact = list.filter(val => val.seri !== exactSeri);
      const list2NotExact = list2.filter(val => val.seri !== exactSeri);
      setListSearch([
        ...withDisplayOrder(listExact),
        ...withDisplayOrder(list2Exact),
        ...withDisplayOrder(listNotExact),
        ...withDisplayOrder(list2NotExact),
      ]);
      if (list.length === 0) {
        setMessage('Số này hiện không có, bạn liên hệ Fanpage hoặc Zalo để được tư vấn thêm');
      }
    }
    setForm2UI({ loading: false });
    document.querySelector('#list-search-results')?.scrollIntoView({
      behavior: 'smooth'
    });
  }

  const showMoneyModal = (val) => {
    modal.show(
      <Col bgWhite round1 shadow p2 width={rV({ xs: 360, md: window.innerWidth * 0.8 })}>
        {/* <MoneyImage {...val} /> */}
        <NewMoney {...val} />
      </Col>
    )
  };

  const withDisplayOrder = (list) => {
    const seriSorted = list.sort((a, b) => a.seri > b.seri ? 1 : -1);
    if (!SITE_INFO.MONEY_DISPLAY_ORDER) return seriSorted;

    const parts = SITE_INFO.MONEY_DISPLAY_ORDER.split(',').map(v => v.trim());
    let results = [];
    let excludeIndexs = [];
    const higherPriorityArr = parts.map(p => seriSorted.filter((val, index) => {
      if (val.short === p) {
        excludeIndexs.push(index);
        return true;
      }
      return false;
    }));
    const otherArr = seriSorted.filter((val, index) => !excludeIndexs.includes(index));

    higherPriorityArr.forEach(arr => {
      results = [...results, ...arr];
    })
    return [
      ...results,
      ...otherArr,
    ];
  };

  const onPlaceOrderDone =  () => {
    setNgaythangnamsinh(['', undefined, '']);
    setYearPairs(['', '']);
    setListSearch([]);
  }

  const renderMainContent = () => {
    return (
      <Col flex1 justifyContent='center'>
        <ScrollView
          contentContainerStyle={{
            flex: 1,
            justifyContent: 'center',
          }}
        >
          <Grid xs='100%' md='any:1:any' flex1 p2={breakpoint !== 'xs'} alignItems={'flex-start'} paddingTop={0} width={'100%'}>
            
            {breakpoint === 'lg' && !ctv ? (
              <Col width={275} mr1>
                <CartSidebar
                  ctv={ctv}
                  onPlaceOrderDone={onPlaceOrderDone}
                />
              </Col>
            ) : (
              <Col />
            )}
            
            <Col middle>
              <SearchForm
                ngaythangnamsinh={ngaythangnamsinh}
                setNgaythangnamsinh={setNgaythangnamsinh}
                onTraCuu={traCuu}
                onTraCuuCapDoi={onTraCuuCapDoi}
                form1Loading={form1Loading}
                yearPairs={yearPairs}
                setYearPairs={setYearPairs}
                form2Loading={form2Loading}
                ctv={ctv}
              />
            </Col>
            {!ctv && (
              <Col width={275} ml1>
                {breakpoint !== 'xs' && <FacebookPage />}
              </Col>
            )}
            
          </Grid>
          {/* <Col flex1 p2={breakpoint !== 'xs'} middle paddingTop={0}>
            <SearchForm
              ngaythangnamsinh={ngaythangnamsinh}
              setNgaythangnamsinh={setNgaythangnamsinh}
              onTraCuu={traCuu}
              onTraCuuCapDoi={onTraCuuCapDoi}
              form1Loading={form1Loading}
              yearPairs={yearPairs}
              setYearPairs={setYearPairs}
              form2Loading={form2Loading}
              ctv={ctv}
            />
          </Col> */}
          {listSearch.length > 0 ? (
            <Grid xs='100%' md='50%' lg='33.33%' p1
              paddingBottom={carts.length > 0 ? 60 : 10}
              middle
              nativeID='list-search-results'
            >
              {listSearch.map((val, i) => {
                const isInCart = !!carts.find(item => item.id === val.id);
                const price = getPrice(val);
                return (
                  <Col key={val.id} m1 p1 round1 overflow={'hidden'} backgroundColor='white' shadow>
                    <ApolloEffect
                      onPress={() => {
                        showMoneyModal({
                          ...val,
                          price
                        });
                      }}
                    >
                      {/* <RatioCol width={'100%'} ratio={316 / 160} backgroundColor={'rgba(0,0,0,0.3)'}>
                        <Col flex1 middle p1>
                          <MoneyImage
                            {...val}
                          />
                        </Col>
                      </RatioCol> */}
                      <NewMoney {...val} />
                    </ApolloEffect>
                    <Row mv1 stretch>
                      <Col flex1>
                        <Row mb1>
                          <Col width={30} height={30} justifyContent='center'>
                            <FontAwesome5 name="money-bill" size={24} color={'rgba(0,0,0,0.3)'} />
                          </Col>
                          <Col ml2 flex1>
                            <Text bold fontSize={14}>{val.code} {val.seri}</Text>
                          </Col>
                        </Row>
                        <Row>
                          <Col width={30} height={30} justifyContent='center'>
                            {/* <MaterialIcons name="attach-money" size={24} color={'rgba(0,0,0,0.3)'} /> */}
                            <Text color={'rgba(0,0,0,0.7)'} fontSize={15}>{val.currency.toLowerCase()}</Text>
                          </Col>
                          <Col ml2 flex1>
                            <Text bold color="red" fontSize={18}>{VarHelper.formatMoney(price)}</Text>
                          </Col>
                        </Row>
                      </Col>
                      <Col flex1 alignItems={'flex-end'} justifyContent='flex-end'>
                        {/* <Text caption mb1>thêm vào giỏ hàng</Text> */}
                        {breakpoint !== 'xs' && (
                          <TouchField width={30} height={30} borderRadius={15} middle mb2
                            onPress={() => {
                              showMoneyModal({
                                ...val,
                                price
                              });
                            }}
                          >
                            <FontAwesome name="expand" size={16} color={'rgba(0,0,0,0.6)'} />
                          </TouchField>
                        )}
                        <Button
                          text={isInCart ? 'Đã chọn' : 'Chọn'}
                          width={100}
                          height={30}
                          borderRadius={15}
                          iconLeft={isInCart ? (
                            <AntDesign name="check" size={24} color="white" />
                          ) : (
                            <EvilIcons name="cart" size={24} color="white" />
                          )}
                          backgroundColor={isInCart ? SITE_INFO.BRAND_COLOR : 'red'}
                          bgHovered={SITE_INFO.BRAND_COLOR}
                          onPress={() => {
                            if (isInCart) setCarts(carts.filter(item => item.id !== val.id));
                            else {
                              setCarts([...carts, { ...val, price }]);
                            }
                          }}
                        />
                      </Col>
                    </Row>

                  </Col>
                );
              })}
            </Grid>
          ) : (
            Boolean(!!message) && (
              <Col p2 middle mb3>
                <Text bold color={IS_DARK_BACKGROUND ? 'white' : undefined}>{message}</Text>
              </Col>
            )
          )}
        </ScrollView>
        {/* <Cart
          onPlaceOrderDone={() => {
            setNgaythangnamsinh(['', undefined, '']);
            setYearPairs(['', '']);
            setListSearch([]);
          }}
          ctv={ctv}
        /> */}
        {/* {carts.length > 0 && (
          <Row
            height={50} shadow
            bottom={0} left={0} right={0}
            backgroundColor='white'
            borderTopWidth={2}
            borderTopColor={SITE_INFO.BRAND_COLOR}
            middle
            // @ts-ignore
            position='fixed'
          >
            <Entypo name="shopping-cart" size={24} color={SITE_INFO.BRAND_COLOR} />
            <Text center ml2>Đã chọn: <Text bold>{carts.length}</Text></Text>
            <Button
              outline
              borderColor={'red'}
              text='Kiểm tra đơn hàng'
              width={200}
              height={30}
              borderRadius={15}
              fontStyle={{
                color: 'red',
                fontWeight: 'bold',
              }}
              bgHovered={'rgba(255, 0, 0, 0.2)'}
              ml2
              onPress={() => {
                modal.show(
                  <SubmitOrder
                    carts={carts}
                    setCarts={setCarts}
                    onPlaceOrderDone={() => {
                      setNgaythangnamsinh(['', undefined, '']);
                      setYearPairs(['', '']);
                      setListSearch([]);
                    }}
                    ctv={ctv}
                  />
                )
              }}
            />
          </Row>
        )} */}
      </Col>
    )
  };

  return (
    <HomeLayout
      onPlaceOrderDone={() => {
        setNgaythangnamsinh(['', undefined, '']);
        setYearPairs(['', '']);
        setListSearch([]);
      }}
    >
      {renderMainContent()}
    </HomeLayout>
  );
}



Home.routeInfo = {
  title: SITE_INFO.NAME,
  path: '/',
};

export default Home;